<template>
  <div class="farm-home">
    <div class="content-item">
      <div class="stat-item" v-for="(item, index) in info" :key="index">
        <img :src="item.img" alt="" />
        <div class="count">
          <p class="num" :style="{ color: item.color }">
            {{ item.count }}
          </p>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="content-item">
      <div class="model-box">
        <div class="title-box">
          <p class="title">当日用水计算模型</p>
          <p class="consult-model">
            参考模型<span class="iconfont" style="color: #4dcbb9"
              >&#xe6e4;</span
            >
          </p>
        </div>
        <div class="estimate-use-water">
          <img src="imgs/farm/water.png" alt="" />
          <div class="water-num">
            <span
              v-for="(item, index) in 6 - useWater.split('').length"
              class="num"
              :key="index"
              >0</span
            >
            <span v-for="(item, index) in useWater.split('')" class="num" :key="index">{{
              item
            }}</span>
            <span class="unit">m³</span>
          </div>
        </div>
        <p class="label">今日预估用水</p>
        <div class="stat-count-box">
          <div class="stat-count-item">
            <p class="num">{{ drainModel.num ? drainModel.num : 0 }}</p>
            <p>今日存栏(头)</p>
          </div>
          <div class="stat-count-item">
            <p class="num">
              {{ drainModel.maxValue ? drainModel.maxValue : 0 }}
            </p>
            <p>实际用水量(m3)</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="alarm-msg-box">
          <div class="alarm-msg-item" v-for="(item, index) in 2" :key="index">
            <p>
              <span class="iconfont" style="color: #ff3f6b">&#xe654;</span>
              <span class="alarm-title">荣昌XXXXXXXXX养殖场-用水量超标</span>
            </p>
            <p class="date">
              <span>2021-06-02 15:58:21</span>
              <span>已读</span>
            </p>
          </div>
        </div>
      </div>
      <div class="farm-map">
        <farm-map
          :farms="farms"
          :currentFarm="currentFarm"
          @farm-click="farmClick"
          @site-click="siteClick"
          @zoom-change="zoomChange"
        ></farm-map>

        <div class="sel-box">
          <Select v-model="farmId" style="width: 200px">
            <Option
              v-for="item in farms"
              :value="item.sourceId"
              :key="item.sourceId"
              >{{ item.mapName }}</Option
            >
          </Select>
        </div>
      </div>
      <div class="model-box">
        <div class="title-box">
          <p class="title">土壤承载力计算模型</p>
          <p class="consult-model">
            参考模型<span class="iconfont" style="color: #4dcbb9"
              >&#xe6e4;</span
            >
          </p>
        </div>
        <div class="estimate-use-water">
          <img src="imgs/farm/N.png" alt="" />
          <div class="water-num">
            <span
              v-for="(item, index) in soilCarryCapacity.split('.')[0]"
              class="num"
              :key="index"
              >{{ item }}</span
            >
            <span class="dot">.</span>
            <span
              v-for="(item, index) in soilCarryCapacity.split('.')[1]"
              class="num"
              :key="index"
              >{{ item }}</span
            >
          </div>
        </div>
        <p class="label">土壤承载力</p>
        <div class="stat-count-box">
          <div class="stat-count-item">
            <p class="num">0</p>
            <p>预估需要粪肥N(kg)</p>
          </div>
          <div class="stat-count-item">
            <p class="num">0</p>
            <p>实际需要粪肥N(kg)</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="alarm-msg-box">
          <div class="alarm-msg-item" v-for="(item, index) in 2" :key="index">
            <p>
              <span class="iconfont" style="color: #ff3f6b">&#xe654;</span>
              <span class="alarm-title"
                >荣昌XXXXXXXXX种植场-承载力打到预警值</span
              >
            </p>
            <p class="date">
              <span>2021-06-02 15:58:21</span>
              <span>已读</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content-item">
      <div class="video-panel">
        <div class="video-title">
          <span>监控视频</span>
          <Select v-model="videoId" style="width: 180px">
            <Option
              v-for="(item, index) in videoList"
              :key="index"
              :value="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </div>
        <div class="video-play" v-if="videoList.length > 0">
          <player :videoInfo="videoInfo"></player>
        </div>
        <div class="no-data" v-else>
          <img src="imgs/empty/empty.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <div class="monitor-panel">
        <div class="head-box">
          <p class="title">养殖场环境监测</p>
          <div class="filter-box">
            <span
              :class="[
                'filter-item',
                monitorType == 'day' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('day')"
              >今日</span
            >
            <span
              :class="[
                'filter-item',
                monitorType == 'week' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('week')"
              >本周</span
            >
            <span
              :class="[
                'filter-item',
                monitorType == 'month' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('month')"
              >本月</span
            >
            <span
              :class="[
                'filter-item',
                monitorType == 'year' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('year')"
              >本年</span
            >
            <DatePicker
              type="daterange"
              style="width: 200px"
              :options="options"
              v-model="monitorDate"
              placement="left-start"
            ></DatePicker>
          </div>
        </div>
        <div class="monitor-data">
          <div class="data-block-box" v-if="monitorData.length > 0">
            <div
              :class="[
                'data-block',
                currentMonitor && currentMonitor.id == item.id
                  ? 'data-block-active'
                  : '',
              ]"
              v-for="(item, index) in monitorData"
              :key="index"
              @click="() => (currentMonitor = item)"
            >
              <img :src="getImgUrl(item.servicePath, item.imgUrl)" alt="" />
              <div>
                <p class="value">{{ item.measure }}</p>
                <p class="value-name">
                  {{ `${item.sensorCategoryName}(${item.sensorCategoryUnit})` }}
                </p>
              </div>
            </div>
          </div>
          <div class="chart" ref="chart" v-if="monitorData.length > 0"></div>
          <div class="no-data" v-else>
            <img src="imgs/empty/empty.png" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "../../../../public/imgs/farm/base.png";
import device from "../../../../public/imgs/farm/device.png";
import facility from "../../../../public/imgs/farm/facility.png";
import inventory from "../../../../public/imgs/farm/inventory.png";
import variety from "../../../../public/imgs/farm/variety.png";
import farmMap from "./map";
import player from "@/components/player";
import moment from "moment";
import * as echarts from "echarts";
export default {
  name: "",
  components: {
    farmMap,
    player,
  },
  data() {
    return {
      options: {
        disabledDate(date) {
          if (moment(date).isAfter(moment(new Date()).subtract(1, "days")))
            return true;
          return false;
        },
      },
      info: [
        { img: base, color: "#CD54F9", name: "养殖场(个)", count: 0 },
        { img: device, color: "#5480F9", name: "设备(个)", count: 0 },
        { img: facility, color: "#9C54F9", name: "设施(个)", count: 0 },
        { img: inventory, color: "#54B7F9", name: "存栏(头)", count: 0 },
        { img: variety, color: "#F99C54", name: "养殖品种(种)", count: 0 },
      ],
      useWater: "0",
      soilCarryCapacity: "0.0",
      farms: [],
      farmId: "",
      currentFarm: null,
      videoList: [],
      videoId: "",
      videoInfo: null,

      monitorType: "year",
      monitorDate: [],

      monitorChart: null,
      //粪污排水模型
      drainModel: {},
      //土壤承载力模型
      capacity: {},
      monitorData: [],
      currentMonitor: null,
      statData: [],
    };
  },
  watch: {
    farmId(n) {
      if (!n) return;
      this.currentFarm = this.farms.filter((item) => item.sourceId == n)[0];
      this.getVideo();
      this.getCount();
      this.getDrainModel();
      this.getCapacity();
      this.getMonitor();
    },
    videoId(n) {
      this.videoInfo = this.videoList.filter((item) => item.id == n)[0];
    },
    currentMonitor() {
      this.getStatData();
    },
    monitorDate(n) {
      if (!n[0]) return;
      this.monitorType = "custom";
      this.getStatData();
    },
  },
  methods: {
    getCount() {
      this.$post(this.$api.STATICTICS_ANALYSIS.FARMING_STAT, {
        farmId: this.farmId,
        siteType: "2",
      }).then((res) => {
        this.info[0].count = res.farmCount;
        this.info[1].count = res.deviceCount;
        this.info[2].count = res.facilityCount;
        this.info[3].count = res.productNum;
        this.info[4].count = res.productCount;
      });
    },
    getFarms() {
      this.$post(this.$api.MAP.MAP_CFG_INFO, {
        farmType: "2",
      }).then((res) => {
        if (res.list.length == 0) return;
        this.farms = res.list;
      });
    },
    farmClick(farm) {
      if (this.farmId == farm.sourceId) {
        this.currentFarm = farm;
      }
      this.farmId = farm.sourceId;
    },
    siteClick(site) {
      if (site.siteType == 7) {
        this.videoId = site.sensorGuids;
      }
    },
    zoomChange(zoom, flag) {
      if (zoom < 13) {
        this.currentFarm = null;
      }
      if (!flag && this.farms.length > 0) {
        this.farmId = this.farms[0].sourceId;
      }
    },

    getVideo() {
      this.$post(this.$api.BUSSDEVICEVIDEO.LIST, {
        farmId: this.farmId,
      }).then((res) => {
        if (res.list.length == 0) {
          this.videoList = [];
          this.videoId = "";
          this.videoInfo = null;
          return;
        }
        this.videoList = res.list;
        this.videoId = res.list[0].id;
        this.videoInfo = res.list[0];
      });
    },
    //获取粪污排水模型
    getDrainModel() {
      this.$post(this.$api.MANURE_DRAIN_MODEL.DRAIN, {
        farmId: this.farmId,
      }).then((res) => {
        // console.log(res);
        this.drainModel = res[0] || {};
        this.useWater = this.drainModel?.maxValue ?? "0";
      });
    },
    //获取土壤承载力模型
    getCapacity() {
      this.$post(this.$api.SOIL_CARRYING_CAPACITY_MODEL.CARRYING_CAPACITY, {
        farmId: this.farmId,
      }).then((res) => {
        // console.log(res);
        this.capacity = res[0];
        this.soilCarryCapacity = this.capacity?.maxValue ?? "0.0";
      });
    },
    getMonitor() {
      this.$post(this.$api.MONITOR.TREATMENT_EFFECT_MONITOR, {
        farmId: this.farmId,
      }).then((res) => {
        this.monitorData = res.list;
        this.currentMonitor = res.list[0];
      });
    },
    getStatData() {
      if (!this.currentMonitor) return;
      this.$post(this.$api.MONITOR.MONITOR_CHART_NEW, {
        guid: this.currentMonitor.guid,
        switchType: this.monitorType,
        sBeginTime:
          this.monitorType == "custom"
            ? moment(this.monitorDate[0]).format("YYYY-MM-DD")
            : "",
        sEndTime:
          this.monitorType == "custom"
            ? moment(this.monitorDate[1]).format("YYYY-MM-DD")
            : "",
        days:
          this.monitorType == "week"
            ? 7
            : this.monitorType == "month"
            ? 30
            : this.monitorType == "custom"
            ? moment(this.monitorDate[1]).diff(
                moment(this.monitorDate[0]),
                "days"
              ) + 1
            : "",
      }).then((res) => {
        this.statData = res || [];
        this.initChart();
      });
    },

    monitorCut(type) {
      if (this.monitorType == type) return;
      this.monitorType = type;
      if (!this.farmId) return;
      this.getStatData();
    },

    initChart() {
      let xData = this.statData[0].child.map((el) => el.showAllTime);
      let data = this.statData[0].child.map((el) => el.value);
      if (!this.monitorChart) {
        this.monitorChart = echarts.init(this.$refs.chart);
      }
      let option = {
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: xData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#7A40F2"],
        series: {
          type: "line",
          smooth: true,
          data,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0.4)",
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0)",
                },
              ],
            },
          },
          lineStyle: {
            shadowOffsetY: 10,
            shadowColor: "rgba(122, 64, 242, 0.3)",
            shadowBlur: 4,
          },
        },
      };
      this.monitorChart.setOption(option);
    },

    resize() {
      if (!this.monitorChart) return
      this.monitorChart.resize();
    },
  },
  mounted() {
    this.getFarms();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>